<template>
	<div class="login-box">
		<div class="logo">
			<img src="@/assets/images/logo.png" alt="">
		</div>
		<div class="login-form">
			<div class="input-content">
				<div class="input-div">
					<input @input="validate" type="number" placeholder="请输入手机号" v-model="mobilePhone"/>
				</div>
			</div>
			<div class="input-content input-content-2">
				<div class="input-div input-div-2">
					<input @input="validate" type="text" placeholder="请输入图形验证码" v-model="captcha"/>
					<div class="catpure" @click="getCaptcha">
						<div class="code" v-html="captchaSvg" id="captchaContainer"></div>
						<span>点击图片刷新</span>
					</div>
				</div>
			</div>
			<div class="input-content input-content-3">
				<div class="input-div">
					<input @input="validate" type="number" placeholder="请输入短信验证码" v-model="code"/>
					<div v-if="firstTime" @click="sendCode" :class="`send-btn1 ${codeDisabled ? 'disabled' : ''}`">
						<span>获取验证码</span>
					</div>
					<div v-if="countdown && !firstTime" class="send-btn2">
						<span>{{timer}}秒后重新发送</span>
					</div>
					<div v-if="!countdown && !firstTime" @click="sendCode" class="send-btn3">
						<span>重新发送</span>
					</div>
				</div>
			</div>
			<div class="btn-content">
				<div class="btn-div" :class="btnDisabled ? 'disabled' : ''" @click="phoneLogin">
					<span>登录</span>
				</div>
			</div>
			<div class="policy-content">
				<span>
					登录即代表您已阅读同意
					<a @click="read('user-service')"> 用户协议 </a>
					和
					<a @click="read('user-privacy')"> 隐私协议</a>
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { login, patientList, authCaptcha, verifyCode, getUserInfo } from '@/request/api/user';
import { Toast } from 'vant';
import { validatePhone } from '@/utils';
const TIMER = 60;
export default {
	data () {
		return {
			mobilePhone: '',
			captchaSvg: '',
			captcha: '',
			code: '',
			firstTime: true,
			countdown: false,
			timer: TIMER,
			interval: null,
			btnDisabled: true,
			codeDisabled: true
		};
	},
	mounted () {
		this.getCaptcha();
	},
	methods: {
		validate () {
			this.btnDisabled = true;
			this.codeDisabled = true;
			if (this.mobilePhone && this.captcha && this.code) {
				this.btnDisabled = false;
			}
			if (this.mobilePhone && this.captcha) {
				this.codeDisabled = false;
			}
			if (this.code.length > 4) this.code = this.code.substr(0, 4);
		},
		sendCode () {
			if (!this.mobilePhone) {
				return this.$root.elMsg('请输入手机号', 'fail');
			}
			if (!validatePhone(this.mobilePhone)) {
				return this.$root.elMsg('请输入正确的手机号', 'fail');
			}
			if (!this.captcha) {
				return this.$root.elMsg('请输入图形验证码', 'fail');
			}
			verifyCode({
				mobilePhone: this.mobilePhone,
				captcha: this.captcha
			}).then(data => {
				if (data && data.ok === 1) {
					this.$root.elMsg('发送成功');
					this.firstTime = false;
					this.countdown = true;
					this.interval = setInterval(() => {
						if (--this.timer <= 0) {
							clearInterval(this.interval);
							this.timer = TIMER;
							this.countdown = false;
						}
					}, 1000);
				} else {
					// Toast('图形验证码错误');
				}
			}).catch(e => {
				this.$root.elMsg('图形验证码错误', 'error');
				console.error(e);
			});
		},
		loginHandle () {
			this.$root.elMsg('登录去啦');
		},
		read (num) {
			this.$root.go('/policy', { id: num });
			// this.$root.elMsg(`读一下《${num === 1 ? '用户协议' : '隐私协议'}》`);
		},
		getCaptcha () {
			const svgContainer = document.getElementById('captchaContainer');
			if (svgContainer) {
				svgContainer.style.display = 'none';
				authCaptcha().then(data => {
					this.captchaSvg = data;
					this.$nextTick(() => {
						const svg = svgContainer.childNodes[0];
						svgContainer.style.display = 'block';
						svg.style.width = '90px';
						svg.style.height = '100%';
					});
				});
			}
		},
		phoneLogin () {
			if (!this.mobilePhone) {
				return this.$root.elMsg('请输入手机号', 'fail');
			}
			if (!validatePhone(this.mobilePhone)) {
				return this.$root.elMsg('请输入正确的手机号', 'fail');
			}
			if (!this.code) {
				return this.$root.elMsg('请输入短信验证码', 'fail');
			}
			const accountType = 'PATIENT';
			login({
				mobilePhone: this.mobilePhone,
				code: this.code,
				accountType,
				openid: this.$route.query.openid || ''
			}).then(data => {
				// console.log('data=======', data);
				if (data) {
					window.sessionStorage.setItem('loginInfo', JSON.stringify(data.token));
					window.sessionStorage.setItem('userInfo', JSON.stringify(data.user));
					this.$store.commit('setUserInfo', { ...data.user });
					// this.mainGetPatients();
					this.getUser();
					// this.getImUserSig();
				} else {
					// Toast('短信验证码错误');
				}
			}).catch(e => {
				Toast('登录错误');
				console.error(e);
			});
		},
		getUser () {
			getUserInfo().then(data => {
				if (data && data === 'retry') {
					this.getUser();
				} else if (data) {
					let backUrl = window.localStorage.getItem('backUrl');
					if (!backUrl) backUrl = '';
					if (!data.hasDefaultPatient) {
						this.$root.go('/add-patient-default');
					} else {
						// const backUrl = window.localStorage.getItem('backUrl');
						if (backUrl) {
							// this.$root.go(this.$route.query.redirectUrl);
							window.localStorage.setItem('backUrl', '');
							if (backUrl.indexOf('/doctor-withdraw') > -1 || backUrl.indexOf('/complete-doctor') > -1) {
								this.$root.replace('/user-center');
							} else {
								this.$root.replace(backUrl);
							}
						} else {
							this.$root.replace('/user-center');
						}
					}
				}
			});
		},
		mainGetPatients () {
			patientList().then(data => {
				if (data && data === 'retry') {
					this.mainGetPatients();
				} else if (data) {
					this.$store.commit('setPatients', data);
					if (this.$route.query.redirectUrl) {
						this.$root.go(this.$route.query.redirectUrl);
					} else {
						this.$root.go('/user-center');
					}
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		}
	}
};
</script>
<style lang="less" scoped>
	.login-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: white;
		height: 100%;
    width: 100%;
		.logo {
			width: 96px;
			height: 96px;
			margin-top: 48px;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.login-form {
			flex: 1;
			margin-top: 38px;
			display: flex;
			flex-direction: column;
			width: 100%;
			background-image: url(../assets/images/login-bg.png);
			background-size: 100% 100%;
			.input-content {
				// width: 100%;
				display: flex;
				padding: 0 31px;
				margin-top: 10px;
				.input-div {
					width: 100%;
					border-bottom: 1px solid #e0e0e0;
					padding: 7px 0;
					display: flex;
					align-items: center;
					justify-content: space-between;
					// background-color: #909090;
					input {
						font-size: 15px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #232830;
						line-height: 22px;
						border: none;
						// height: 100%;
						width: 100%;
						flex: 1;
						background: none;
					}
					.catpure {
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						.code {
							width: 86px;
							height: 34px;
						}
						span {
							font-size: 10px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #BEBEBE;
							line-height: 10px;
						}
					}
					.send-btn1 > span {
						font-size: 13px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #FF6450;
						line-height: 13px;
						white-space: nowrap;
					}
					.disabled > span {
						color: #BEBEBE;
					}
					.send-btn2 {
						font-size: 13px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #BEBEBE;
						line-height: 13px;
						white-space: nowrap;
					}
					.send-btn3 {
						font-size: 13px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #FF6450;
						line-height: 13px;
						white-space: nowrap;
					}
				}
				.input-div-2 {
					align-items: flex-end;
				}
			}
			.input-content-2 {
				height: 59px;
				margin-top: 5px;
			}
			.input-content-3 {
				margin-top: 30px;
			}
			.btn-content {
				display: flex;
				padding: 0 50px;
				margin-top: 63px;
				.btn-div {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
					border-radius: 22px;
					padding: 15px 0;
					span {
						font-size: 15px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #FFFFFF;
						line-height: 12px;
						display: flex;
					}
				}
				.btn-div.disabled {
					opacity: 0.5;
				}
			}
			.policy-content {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 24px;
				span {
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #959595;
					line-height: 12px;
					a {
						color: #FF6450;
					}
				}
			}
		}
	}
</style>